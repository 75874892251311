<template>
  <div
    :id="uid"
    class="v-ui-option-slider"
    :data-test-id="dataTestId && `${dataTestId}-slider`"
    v-on-resize="{ onResize: refreshActive, once: false }"
    v-show="!showSelect"
  >
    <label
      class="v-ui-option-slider__radio"
      :class="{ 'v-ui-option-slider__radio--place-divider': item === selected }"
      v-for="(item, index) in options"
      :id="`${uid}-${index.toString()}`"
      :key="`${uid}-${index.toString()}`"
      :inert="isItemDisabled(item)"
    >
      <input
        :name="`radio-${uid}`"
        type="radio"
        :value="item"
        v-model="selected"
        :disabled="isItemDisabled(item)"
      />
      <span
        class="v-ui-option-slider__text"
        :class="{ disabled: isItemDisabled(item) }"
      >
        <slot
          :value="item"
          name="option"
        />
      </span>
    </label>
  </div>
  <ui-select
    v-if="showSelect"
    :label="label"
    :options="options"
    v-model:selected="selected"
  >
    <template #option="option">
      <slot
        :value="option.value"
        name="option"
      />
    </template>
  </ui-select>
</template>

<script generic="T" setup lang="ts">
import { type VElement, vOnResize } from '@arora/common'

const { isItemDisabled = () => false } = defineProps<
  VElement & {
    options: T[]
    isItemDisabled?: (t: T) => boolean
  }
>()
const selected = defineModel<T>('selected')

const uid = useId()

const showSelect = ref<boolean>(false)

function refreshActive(): void {
  const element = document.querySelector(`#${uid}`)

  if (!element) {
    console.error('HOW?')
    return
  }

  showSelect.value = element.scrollWidth > element.clientWidth
}

function checkActiveIndex(): void {
  if (selected.value && isItemDisabled(selected.value)) {
    selected.value = undefined
  }
}

onMounted(checkActiveIndex)

onUpdated(() => {
  refreshActive()
  checkActiveIndex()
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-ui-option-slider {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border-radius: variables.$BorderRadiusInput;
  background-color: variables.$Mono100;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  padding: 0.85rem 0;

  &__radio {
    flex: 1;
    text-align: center;

    &:has(input:checked) {
      border-color: variables.$Mono100; // take background color to make border seem like removed
    }

    &:last-child {
      border: none;
      margin-right: 0; // Remove negative margin for the last item
    }

    border-right: 1px solid variables.$Mono300;
    margin-right: -1px;

    input {
      display: none;
      &:checked {
        + {
          .v-ui-option-slider__text {
            background-color: variables.$SecondaryBackgroundColor;
            color: variables.$SecondaryColor;
            font-weight: 600;

            &:hover {
              background: variables.$SecondaryBackgroundColorHover;
            }
          }
        }
      }
    }
  }

  &__text {
    color: variables.$Mono1000;
    margin-top: -0.85rem;
    margin-bottom: -0.85rem;
    height: calc(100% + 1.7rem);
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: variables.$BorderRadiusInput;
    border: none;
    padding: 0.85rem;
    transition: all 0.15s ease-in-out;

    &:hover {
      color: variables.$Mono800;
    }

    &.disabled {
      color: variables.$Mono400;
    }
  }
}
</style>
